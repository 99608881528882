.progress_bar_wrapper {
    margin-top: 1em;
    display: flex;
    width: 100%;

    .bar {
        height: 1em;
        width: 100%;

        .background {
            height: 1em;
            width: 100%;
            background-color: #f4f1ef;
            border-radius: 15px;

            .fill {
                height: 1em;
                height: 100%;
                border-radius: 15px;
                background-color:  #abcbb8;;
            }
        }

        
    }

    .count {
        margin: 0 2em;
    }
}