.pagination_wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .current_page_container {
        font-weight: bold;
        color: #adadad;

        .current_page {
            font-size: 2em;
            color: #7a68bd;
        }
    }

    .nav_button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: lightgray;
        border-radius: 15px;
        height: 30px;
        border: none;
        padding: 1em;
        background-color: #ffffff;
        color: #626262;
        border: 1px solid #e6e6e6;
        cursor: pointer;
        width: 80px;
    
        &:hover {
            background-color: #7a68bd;
            border: 1px solid #7a68bd;
            color: #ffffff;
        }
    
        &:disabled {
            background-color: #f3f3f3;
            border: 1px solid lightgray;
            color: lightgray;
            cursor: auto;
        }
        
    }

    .prev_button {
        margin: 0 2em 0 0;
    }

    .next_button {
        margin: 0 0 0 2em;
    }
    
}