.course_title {
    text-align: center;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.25em;
    color: #e690cc;
}

.classroom_wrapper {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 576px) { //mobile
        flex-direction: column;
        min-height: 140vh;

    }
    gap: 0.5em;
    width: 100%;
    padding: 0 0.5em;
    height: calc(100vh - 80px - 6em);

    .content_container {     
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 80vh;
        background-color: #ffffff;
        padding: 0.5em;
        margin: 0;
        color: #626262;
        @media only screen and (min-width: 576px) { //desktop
            width: 35%;
            height: calc(100vh - 80px - 6em);
            min-height: 80vh;
        }
        border-radius: 15px;
        border: 1px solid #d5e7d8;
        overflow-x: hidden;

    
        .page_title {
            padding-left: 0.5em;
            margin-bottom: 0.5em;
            font-size: 1.25em;
            
        }
        .course_content {
            width: 100%;
            height: calc(100% - 60px);
            overflow-y: scroll;
            padding: 0em 2em 0em 1em;
            margin: 0;
            font-size: 1em;
        }

         /* width */
         ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #bbbbbb;
            border-radius: 5px;
        }
        
    }
   
}
