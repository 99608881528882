.landing_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: none;
    
    .header {
        // height: 400px;
        background-image: url("../../assets/barchart.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center 75%;
        padding: 0 2.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 2em;

            img {
                width: 50%;
                max-width: 500px;
            }
    
            h1 {
                color: #e690cc;
                font-size: calc(min(6vw, 4em));
                width: calc(min(100%, 1000px));
                text-align: center;
                margin: 1em;
                font-family: "Pixelify Sans", sans-serif;
                font-optical-sizing: auto;
                font-weight: 800;
                font-style: normal;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        display: flex;
        width: 100%;

        .banner {
            display: flex;
            flex-direction: column;
            background-color: transparent;
            color: #c45fa6;
            border-radius: 15px;
            padding: 0 2em;
            text-align: center;
            width: 100%;

            h2 {
                margin: 1em auto;
                font-size: calc(min(4vw, 2em));
                z-index: 5;
                
            }

            img {
                border-radius: 15px;
                width: 100%;
                border: 1px solid #e6e6e6;
            }
        }

        .details {
            color: #9c9c9c;
            font-size: calc(min(3vw, 1.25em));;
            line-height: 1.5;
            line-break: 1.5;
            text-align: left;
            width: calc(min(100%, 1000px));
            margin-top: 2em;
            display: flex;
            flex-direction: column;

            .floating {
                margin: 0;
                padding: 1em;
            }

           .details_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            padding: 0 2em;

            .bullet {
                background-color: #ffffff;
                padding: 1em;
                width: 100%;
                list-style: none;
                border-radius: 15px;
                border: 1px solid #d5e7d8;

                .kw {
                    color: #aba2c3;
                }
    
                strong {
                    font-size: calc(min(3.5vw, 1.5em));
                    font-family: "Pixelify Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    color: #e690cc;
                }
            }
           }
        }

        .primary_button {
            height: calc(70px - 1em);
            display: flex;
            align-items: center;
            justify-content: center;
            border:none;
            border-radius: 10px;
            padding: 0 1em;
            margin: 0.5em;
            font-size: 1.1em;
            font-weight: bold;
            cursor: pointer;
            width: fit-content;
            background-color: #7a68bd;
            border: 1px solid #7a68bd;
            color: #ffffff;
            &:hover {
                background-color: #444a7c;
                border: 1px solid #444a7c;
            }
        }
    }
}

