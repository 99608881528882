.sign_up_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 80px);


    .logo {
        margin-top: 1em;
        cursor: pointer;
        filter:saturate(0);
        &:hover {
            filter:saturate(1) ;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #d5e7d8;
        border-radius: 15px;
        margin:1em;
        padding: 1em 2em;

        .form_title {
            font-family: "Pixelify Sans", sans-serif;
            font-optical-sizing: auto;
            font-style: normal;
            color: #e690cc;
        }

        input {
            width: min(40vw, 600px);
            height: 2.5em;
            border-radius: 5px;
            border: 1px solid #BEBEBE;
            margin: 0.5em;
            padding: 0.5em;
        }

        .names {
            display: flex;
            .name_field {
                width: calc(min(20vw, 300px) - 0.5em);
            }
        }

        .error {
            color: rgb(155, 57, 57);
        }


        button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 1.25em 2em;
            font-size: 1.1em;
            font-weight: bold;
            width: fit-content;
            cursor: pointer;
            background-color: #7a68bd;
            border: 1px solid #7a68bd;
            color: #ffffff;
            &:hover {
                background-color: #444a7c;
                border: 1px solid #444a7c;
            }

            &:disabled {
                background-color: #e3e3e3;
                border: 1px solid #e3e3e3;
                cursor: auto;
                &:hover {
                    background-color: #e3e3e3;
                }
            }
        }

        
        

    }

    .help {
        margin-top: 3em;
        font-size: 0.75em;

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}