.controls_wrapper {
    position: relative;
    width: 100%;
    // pointer-events: none;
    z-index: 5;
    display: flex;
    justify-content: flex-end;

    .button_wrapper {  
        display: flex;
        padding: 0.15em;
        background-color: transparent;
        border-radius: 5px;

        .control_button {
            font-family:'Inter', sans-serif;
            position:relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            color: gray;
            background-color: white;
            height: 35px;
            padding: 1em;
            border-radius: 5px;
            margin: 0.25em;
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
                color: rgb(85, 85, 85)
            }

            &:disabled {
                background-color: #f0f0f0;
                color: #c6c6c6;
                cursor: auto;
            }

            .icon {
                height: 2em;
                width: 2em;
                margin-right: 0.5em
            }
        }


    }
}