.payment_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    img {
        max-height: 35px;
    }

    .pricing_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, calc(40% - 1em)));
        gap: 2em;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 2em;

        .price_container {
            display: flex;
            justify-self: center;
            flex-direction: column;
            align-items: center;
            margin: 1em;
            padding: 1em;
            border: 1px solid #d5e7d8;
            background-color: #FDFDFD;
            width: 100%;
            height: 100%;
            padding-top: 2em;
            border-radius: 10px;

            .price_title {
                font-size: 1.75em;
                font-weight: bold;
                font-family: "Pixelify Sans", sans-serif;
                    font-optical-sizing: auto;
                    font-style: normal;
                    color: #e690cc;
            }

            .price_subtitle {
                font-size: 1.25em;
                font-weight: bolder;
                color: #81B897;

            }

            .price_description {
                margin: 1em;

            }

            .buy_button {
                border: none;
                border-radius: 5px;
                font-size: 1.2em;
                font-weight: bold;
                text-transform:uppercase;
                margin: 1em;
                padding: 0.5em 2em;
                cursor: pointer;
                background-color: #7a68bd;
                border: 3px solid #7a68bd;
                color: #ffffff;
                &:hover {
                    background-color: #444a7c;
                    border: 3px solid #444a7c;
                    }
            }
        }
    }
}