.preview_wrapper {
    display: flex;
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
    align-items: center;
    justify-content: center;
    padding: 1em;
    border-radius: 8px;
    border: 1px solid #d5e7d8;
    background-color: #FDFDFD;
    width: 100%;
    margin: 0.5em;
    overflow: hidden;
    gap: 1em;
    cursor: pointer;
    &:hover {
        scale: 1.05
    }

    .preview_img {
        display: flex;
        justify-content: center;
        width: 200px;
        height: 150px;
        overflow: hidden;
        filter: saturate(0.8) opacity(0.65);
    }

    .preview_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        // width: calc(100% - 200px - 2em);

        .title {
            font-family: "Pixelify Sans", sans-serif;
            font-optical-sizing: auto;
            font-style: 800;
            color: #e690cc;
            font-size: 1.25em;
        }

        .description {
            color: darkgray
        }

        .details {
            margin-top: 1em;
            font-size: 0.8em;
            font-weight: bold;
            color: #BEBEBE;
            text-align: left;
        }
    }
}

.locked_preview {
    opacity: 50%;
    pointer-events: none;
}