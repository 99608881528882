* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;

}

body {
    display: flex;
    width: 100vw;
    justify-content: center;
    background-color: #f4f8f2;
    margin: 0;
    overflow-x: hidden;

}

.app {
    display: flex;
    flex-direction: column;
    width: calc(min(100vw, 1400px));
    min-height: 100vh;

}