.editable_input_wrapper {
    display: grid;
    grid-template-columns: 25% 55% 10% 10%;
    align-items: center;
    width: (calc(min(calc(100% - 2em), 600px)));
    gap: 0.5em;
    margin-bottom: 0.5em;

    .label {
        display: flex;
        margin-right: 1em;
        width: fit-content;
    }

    .field {
        height: 2em;
        border-radius: 5px;
        border: 1px solid #d0d0d0;
        padding: 0.5em;
        
    }

    .button {
        border-radius: 5px;
        border: 1px solid #BEBEBE;
        padding: 0.5em;
        cursor: pointer;
        background-color: #7a68bd;
        border: 1px solid #7a68bd;
        color: #ffffff;
        &:hover {
            background-color: #444a7c;
            border: 1px solid #444a7c;
        }
    }
}

.error {
    color: rgb(155, 57, 57);
}