.container_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    padding: 0;
    width: 100%;
    
    background-color: transparent;

    h2 {
        margin-top: 0
    }
}

