.dashboard_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 80px);
    padding: 0 1em;
    color: #9c9c9c;

    .logo_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;

        .logo {
            width: 20%;
            margin-top: 1em;
            cursor: pointer;
            filter:saturate(0);
            &:hover {
                filter:saturate(1);
            }
        }
    }
    
    .menu { 
        display: flex;
        flex-direction: row;
        margin-bottom: 1em;
        width: 100%;
        
        .link {
            padding: 0.5em 1em;
            display: flex;
            border: 1px solid #E9E1DC;
            border-radius: 8px;
            margin-right: 1em;
            color: #CBAC8A;

            cursor: pointer;

            &:hover {
                background-color: #E9E1DC;
            }
            
        }

        .selected_link {
            padding: 0.5em 1em;
            display: flex;
            background-color: #ffffff;
            font-weight: bold;
            border: 1px solid #CBAC8A;
            border-radius: 8px;
            margin-right: 1em;
            cursor:auto;
            pointer-events:none;
            color: #CBAC8A;
        }
    }

    .message {
        margin-top: 2em;
    }

}

.horizontal_line {
    border: 1px solid #E9E1DC;
    width: 100%;
    margin: 1em 0;
}

.container_link {
    text-decoration: underline;
    cursor: pointer;
}

.spacer {
    display: flex;
    height: 10px;
}
.course_preview_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 0.5em;
    width: 100%;
    

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #F0F0F0;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #9a9a9a71;
    }
}
