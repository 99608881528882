.nav_wrapper {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    img {
        height: 60%;
        max-width: 35px;
        margin-left: 0.5em;
        padding: 0.25em;
        cursor: pointer;

        &:hover {
            scale: 1.1;
        }
    }
    
    .welcome {
        background-color: transparent;
        color: #CBAC8A;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &:hover {
            background-color: #ffffff;
            border: 1px solid #e6e6e6;
        }
    }

   .auth_button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;


    button {
        border:none;
        border-radius: 5px;
        padding: 0.5em 2em;
        margin: 0.5em;
        font-weight: bold;
        font-size: calc(min(2vw, 15px));
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .primary_button {
        background-color: #7a68bd;
        color: #ffffff;
        border: 1px solid #7a68bd;
        &:hover {
            background-color: #444a7c;
            border: 1px solid #444a7c;
        }
    }

    .secondary_button {
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        color: #626262;
        white-space: nowrap;
        &:hover {
            background-color: #eacdae;
            color: #ffffff;
            border: 1px solid #eacdae
        }
    }
   }

   .course_button_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        button {
            border-radius: 5px;
            padding: 0.5em 2em;
            margin: 0.5em;
            font-weight: bold;
            font-size: calc(min(2vw, 15px));
            cursor: pointer;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .primary_button {
            background-color: #7a68bd;
            border: 1px solid #7a68bd;
            color: #ffffff;
            &:hover {
                background-color: #444a7c;
                border: 3px solid #444a7c;
            }
        }
    
        .secondary_button {
            background-color: #fcfcfc;
            border: 1px solid #e6e6e6;
            color: #626262;
            &:hover {
                background-color: #eacdae;
                color: #ffffff;
                border: 3px solid #eacdae;
            }
        }

    }
}