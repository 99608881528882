.loader_wrapper {
    display: flex;
    justify-content: center;
    background-color: rgb(112, 112, 112);

    .loader_container {
        position: absolute;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1em;
        padding: 1em;
        background-color:#FFFFFF;
        border-radius: 10px;
        box-shadow: 0 0 20px #cecece;

        .loader_svg {
            stroke-linecap: round;
            .bg_path {
                stroke: #e7f3eb;
                fill: #e7f3eb;
            }
            .top_path {
                stroke: #81B897;
                fill: #81B897;
            }
        }

        span {
            font-family: Inter;
        }
    }
}