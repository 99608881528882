.editor_wrapper *
    {
        font-family: monospace;
        box-sizing: border-box;
    }

.editor_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 40%;
}