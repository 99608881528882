.code_panel_wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-height: 80vh;
    border-radius: 15px;
    border: 1px solid #d5e7d8;
    @media only screen and (min-width: 576px) { //desktop
        width: 65%;
        height: 100%;
    }

    font-family: monospace !important;
}