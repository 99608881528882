.console_wrapper {
    flex-direction: column;
    height: calc(calc(100% - 35px)*0.6);
    overflow: hidden;
        
    .output_menu {
        display: flex;
        gap: 0.5em;
        margin: 0.5em 2em;

        .menu_link {
            font-family: Inter;
            font-size: 0.8em;
            display: flex;
            align-items: center;
            border: 2px solid #e6e6e6;
            border-radius: 5px;
            margin: 0;
            padding: 0.5em;
            color: gray;
            cursor: pointer;
        }

        .selected_menu_link  {
            font-family: Inter;
            font-size: .8em;
            display: flex;
            align-items: center;
            border: 2px solid #e690cc;
            border-radius: 5px;
            margin: 0;
            padding: 0.5em;
            background-color: #e690cc;
            color: #ffffff;
            cursor: auto;
        }
    }
    
    .output{
        white-space: pre-wrap;
        padding: 1em;
        margin: 0 2em 2em;
        height: calc(100% - 100px);
        width: calc(100% - 4em);
        border: 1px solid #eaeaea;
        border-radius: 5px;
        overflow-y: scroll;  

        /* width */
        &::-webkit-scrollbar {
            width: 15px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #81b8977a;
        }
        margin-bottom: 1em;   
    }
    
    
    .errors {
        color: rgb(92, 10, 10);
        width: calc(100% - 2em);
        white-space: pre-wrap;
        padding: 1em;
        margin: 0 2em 2em;
        height: calc(100% - 100px);
        width: calc(100% - 4em);
        border: 1px solid rgb(234, 234, 234);
        border-radius: 5px;
        overflow-y: scroll;  

        /* width */
        &::-webkit-scrollbar {
            width: 15px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #bbbbbb7a;
        }
    }

    .try_again {
        display: flex;
        width: 100%;
        text-align: end;
        padding: 0 2em;
        font-weight: bold;
        visibility: hidden;

        .appearing_message {
            color:rgb(206, 93, 93);
            visibility: visible;
        }
    }

    .invisible {
        visibility: hidden;
    }
}