.profile_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
    color: #9c9c9c;


    .logo_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;

        .logo {
            margin-top: 1em;
            cursor: pointer;
            filter:saturate(0);
            width: 50%;
            max-width: 100px;
            &:hover {
                filter:saturate(1)
            }
        
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        background-color: #ffffff;
        margin: 1.5em;
        padding: 1em;
        width: 80%;
        border-radius: 15px;
        border: 1px solid #d5e7d8;

        .profile_section {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 2em;
            align-items: flex-start;

            a {
                text-decoration: none;
                color: #9f8eba;
                font-weight: bold;
            }
        }
    }

    .help {
        font-size: 1em;

        span {
            text-decoration: underline;
            cursor: pointer;
            color: #9f8eba;
            font-weight: bold;
        }
    }
}